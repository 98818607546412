<template>
  <div class="main-home">
    <div class="main">
      <div class="main-left">
        <!-- <div class="main-left-title">诊所管理</div> -->
        <div 
          v-for="(item, index) in menuList"
          class="left-tab"
          :key="index"
          :class="{
            'active': index === curIndex
          }"
          @click="handleChangTab(item, index)"
        >
          <img class="left-tab-icon" :src="index === curIndex ? item.activeIcon : item.icon" alt="">
          <div class="left-tab-txt">{{ item.title }}</div>
        </div>
      </div>
      <div class="main-right">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import tabDataIcon from '@/assets/images/tab_data_icon.png';
import tabDataIconActive from '@/assets/images/tab_data_icon_active.png';
import tabClinicIcon from '@/assets/images/tab_clinic_icon.png';
import tabClinicIconActive from '@/assets/images/tab_clinic_icon_active.png';
import tabOrderIcon from '@/assets/images/tab_order_icon.png';
import tabOrderIconActive from '@/assets/images/tab_order_icon_active.png';
import tabFinanceIcon from '@/assets/images/tab_finance_icon.png';
import tabFinanceIconActive from '@/assets/images/tab_finance_icon_active.png';
export default {
  name: 'Main',
  data() {
    return {
      curIndex: 0,
      menuList: []
    }
  },
  computed: {
    ...mapState({
      customerTyeCodeName: (state) => state.user.customerTyeCodeName,
      userInfo: state => state.user.userInfo,
      isLogin: state => state.user.isLogin
    }),
  },
  watch: {
    $route(to, from) {
      const name = to.name
      // this.filterCurIndex(name)
    }
  },
  mounted() {
    this.menuList = [
      { title: '数据统计', pathName: 'statistics', icon: tabDataIcon, activeIcon: tabDataIconActive },
      { title: `${this.customerTyeCodeName}管理`, pathName: 'clinicManager', icon: tabClinicIcon, activeIcon: tabClinicIconActive },
      { title: '订单管理', pathName: 'clinicOrder', icon: tabOrderIcon, activeIcon: tabOrderIconActive },
      { title: '设备管理', pathName: 'clinicEquipment', icon: tabDataIcon, activeIcon: tabDataIconActive },
      { title: '就诊管理', pathName: 'clinicDiagnose', icon: tabDataIcon, activeIcon: tabDataIconActive },
      { title: '患者管理', pathName: 'clinicPatient', icon: tabDataIcon, activeIcon: tabDataIconActive },
      // { title: '财务管理', pathName: 'finance', icon: tabFinanceIcon, activeIcon: tabFinanceIconActive },
    ]
    const { name } = this.$route
    this.filterCurIndex(name)
  },
  methods: {
    // goto(name, index) {
    //   this.curIndex = index
    //   this.$router.push({ name })
    // },
    filterCurIndex(name) {
      this.curIndex = this.menuList.findIndex(menu => menu.pathName == name)
      this.curIndex = name == 'diagnoseHistory' ? 2 : this.curIndex
    },
    handleChangTab(tab, index) {
      window.aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "_sjy.unitManagement.clk",
          "CLK",
          {
            param1: this.$route.name,
          },
        ],
      });
      this.curIndex = index;
      this.$router.push({
        name: tab.pathName
      });
    }
  }
}
</script>

<style lang="less" scoped>
.main-home {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  padding-top: 62px;
  flex: 1;
  overflow: auto;
  background-color: #f5f5f5;

  .main {
    display: flex;
    width: 1200px;
    height: 100%;
    min-height: calc(100vh - 180px);
    margin: 24px auto 24px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
  }

  .main-left {
    width: 180px;
    min-height: 100%;
    flex-shrink: 0;
    padding-top: 10px;
    border-right: 1px solid #DEE0E3;
  }

  .main-right {
    width: 1020px;
    flex: 1;
    padding: 24px;
    box-sizing: border-box;
  }
}
.main-left-title {
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-left: 22px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #252626;
}
.left-tab {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding-left: 20px;
  cursor: pointer;
  box-sizing: border-box;
  &.active {
    background: #F0FCFC;
    .left-tab-txt {
      font-weight: 500;
      color: #19B2AD;
    }
  }
  .left-tab-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .left-tab-txt {
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #47515C;
    line-height: 24px;
  }
}
</style>
